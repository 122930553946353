<template>
  <main>
      <div class="modal fade" id="modalUser" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
          data-bs-backdrop="status">
          <div class="modal-dialog modal-xl modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="modalTitle">Datos de Usuario</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p><b>Nombre de la Universidad de Procedencia:</b> {{ viewDataArray.instituteEducation }}</p>
                    <p><b>Carrera:</b> {{ viewDataArray.careerStudent }}</p>
                    <p v-if="viewDataArray.informationStudent?.status === 'exuniversitario'"><b>Fecha de Inicio de Estudios:</b>{{ moment(viewDataArray.dateComprobanteInicio).add(1, 'days').format("DD/MM/YYYY") }}</p>
                    <p v-if="viewDataArray.informationStudent?.status === 'exuniversitario'"><b>Fecha de Termino de Estudios:</b>{{ moment(viewDataArray.dateComprobanteFin).add(1, 'days').format("DD/MM/YYYY") }}</p>
                    <p v-if="viewDataArray.informationStudent?.status != 'exuniversitario'"><b>Fecha de Expedición del Comprobante:</b>{{ moment(viewDataArray.dateComprobanteInicio).add(1, 'days').format("DD/MM/YYYY") }}</p>
                    <p v-if="viewDataArray.informationStudent?.status != 'exuniversitario'"><b>Fecha de Vencimiento del Comprobante:</b>{{ moment(viewDataArray.dateComprobanteFin).add(1, 'days').format("DD/MM/YYYY") }}</p>
                    <p v-if="viewDataArray.informationStudent?.status != 'exuniversitario'"><b>Fecha Aproximada Fin de Carrera:</b>{{ moment(viewDataArray.dateTermino).add(1, 'days').format("DD/MM/YYYY") }}</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="aceptUser()">
                          Aceptar Verificación
                      </button>
                      <!-- Botón para abrir el modal de eliminación -->
                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modalDeleteConfirmation">
                            Eliminar Verificación
                        </button>

                      <!-- <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUser()">
                          Eliminar Verificación
                      </button> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- Modal para Confirmar Eliminación -->
        <div class="modal fade" id="modalDeleteConfirmation" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">Confirmar Eliminación</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>Por favor, escribe el motivo de la eliminación:</p>
                <textarea v-model="deleteMessage" class="form-control" rows="4" placeholder="Escribe el mensaje aquí..."></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-danger" @click="confirmDelete">Confirmar y Enviar Correo</button>
            </div>
            </div>
        </div>
        </div>

      <div class="card-title mt-2">   
        <h3 class="text-dark">Historial de Verificación</h3> 
      </div>
      <div class="card card-custom gutter-b">
          <div class="card-header bg-primary pb-3 pt-3">
              <div class="form-group row">
                  <div class="col-lg-6">
                      <div class="row">
                          <div class="col-lg-6">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <table class="table align-middle table-responsive table-hover caption-top" style="font-family: Poppins">
              <thead>
                  <tr align="center">
                      <th scope="col">Nombre Completo</th>
                      <th scope="col">Usuario</th>
                      <th scope="col">Fecha de Registro</th>
                      <th scope="col">Documentos</th>
                      <th scope="col">Estatus Verificación</th>
                      <th scope="col">Fecha de Inicio Docs</th>
                      <th scope="col">Fecha Fin Docs</th>
                      <th scope="col">Opciones</th>
                  </tr>

              </thead>
              <tbody v-if="response.loading">
                  <tr>
                      <td colspan="7">
                          <div class="d-flex justify-content-center">
                              <div class="spinner-border text-danger" role="status">
                                  <span class="visually-hidden">Loading...</span>
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
              <tbody v-else v-for="user in dataPage" v-bind:key="user">
                  <tr align="center">
                      <td style="width:18%">
                          {{ user.informationStudent.name }}
                          {{ user.informationStudent.lastname }}
                          {{ user.informationStudent.secondlastname }}
                      </td>
                      <td style="text-transform: capitalize">{{ user.informationStudent.status }} </td>
                      <td> {{ moment(user.informationStudent.createdAt).format("DD/MM/YYYY") }} </td>
                      <td style="display: flex; flex-direction: row; justify-content: center; align-content: center;">
                          <button type="button" class="btn btn-icon btn- btn-hover-warning btn-sm" title="Ver Comprobante">
                              <a :href="user.fileComprobante?.link" target="_blank" class="btn btn-primary">
                                  <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                              </a>
                          </button>
                          <button type="button" class="btn btn-icon btn-hover-warning btn-sm"
                              title="Ver INE">
                              <a :href="user.fileINE?.link" target="_blank" class="btn btn-primary">
                                  <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                              </a>
                          </button>
                      </td>
                      <td>
                          <p class="badge"
                              :class="{ 'bg-danger': !user.verificationStatus, 'bg-success': user.verificationStatus, }">
                              {{ user.verificationStatus ? "Verificado" : "Pendiente" }}
                          </p>
                      </td>
                      <td> {{ moment(user.dateComprobanteInicio).add(1, 'days').format("DD/MM/YYYY") }} </td>
                      <td> {{ moment(user.dateComprobanteFin).add(1, 'days').format("DD/MM/YYYY") }} </td>
                      <td align="center">
                          <button type="button" class="btn btn-icon btn-success btn-hover-primary btn-sm mx-3"
                              data-bs-toggle="modal" data-bs-target="#miModal" @click="viewData(user)"
                              title="Ver Usuario">
                              <box-icon type='solid' color="white" name='show'></box-icon>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </table>
          <!-- Paginador -->
          <nav aria-label="Page navigation example" v-show="listStudents.length > 0">
              <ul class="pagination justify-content-center">
                  <li v-if="this.actual > 1" class="page-item" v-on:click="getPreviousPage()">
                      <a class="page-link" href="#">Anterior</a>
                  </li>
                  <li v-for="page in totalPagesNumber" v-bind:key="page" v-on:click="getDataPages(page)"
                      v-bind:class="isActive(page)" class="page-item">
                      <a v-if="page < this.actual + 7 && page > this.actual - 7" class="page-link" href="#">{{ page
                          }}</a>
                  </li>
                  <li class="page-item" v-on:click="getNextPage()">
                      <a class="page-link" href="#">Siguiente</a>
                  </li>
              </ul>
          </nav>
      </div>
  </main>
</template>

<script>
import { AdminService } from "@/services";
import moment from "moment";
moment.locale("es");

export default {
  data: () => ({
      moment: moment,
      elementPage: 20,
      dataPage: [],
      data: {},
      listStudents: {},
      listStudentsTemp: {},
      viewDataArray: {},
      actual: 1,
      estadoPendiente: "Pendiente",
      estadoTodo: "Todo",
      estadoAceptado: "Aceptado",
      response: {},
      totalPagesNumber: 0,
      newPromo: {
        code: '',
        description: '',
        expirationDate: '',
        type: '' // Para identificar el tipo de código
      },
      deleteMessage: '',
  }),
  created() {
      this.students();
  },
  methods: {
      async students() {
          try {
              this.response.loading = true;
              await AdminService.getVerificadosAdmin({ search: "" }).then((res) => { 
                this.listStudents = res.data.data;
                console.log(this.listStudents)
                  this.listStudentsTemp = res.data.data;
                  this.totalPages(this.listStudents.length)
                  this.listStudents.length > 0 ? this.getDataPages(1) : this.response.loading = true;
                  this.response.loading = false;
              });
              this.response.loading = false;
          } catch (error) {
              this.response.loading = false;
          }
      },
      async aceptUser() {
          let data = { id: this.id };
          await AdminService.aceptVerification(data).then((res) => {
              this.$swal({
                  position: "top-center",
                  icon: "success",
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 3500,
              });
              this.students();
          }).catch((e) => {
              this.$swal({
                  position: "top-end",
                  icon: "error",
                  title: e.response.data.message,
                  showConfirmButton: false,
                  timer: 2500,
              });
          });
      },
    //   deleteUser() {
    //       AdminService.deleteUserVerification({ id: this.id })
    //           .then((res) => {
    //               this.$swal({
    //                   position: "top-center",
    //                   icon: "success",
    //                   title: res.data.message,
    //                   showConfirmButton: false,
    //                   timer: 2500,
    //               });
    //               this.students();
    //           })
    //           .catch((e) => {
    //               this.$swal({
    //                   position: "top-end",
    //                   icon: "error",
    //                   title: e.response.data.message,
    //                   showConfirmButton: false,
    //                   timer: 2500,
    //               });
    //           });
    //   },
      viewData(user) {
          this.viewDataArray = user;
          this.id = user._id;
          let myModal = new window.bootstrap.Modal('#modalUser', { keyboard: false })
          myModal.show();
      },
      async totalPages(data) {

          this.totalPagesNumber = Math.ceil(data / 20);

      },
      getDataPages(numberPage) {
          this.actual = numberPage;
          this.dataPage = [];
          let start = numberPage * this.elementPage - this.elementPage;
          let end = numberPage * this.elementPage;
          this.dataPage = this.listStudents.slice(start, end);
      },
      getPreviousPage() {
          if (this.actual > 1) {
              this.actual--;
          }
          this.getDataPages(this.actual);
      },
      getNextPage() {
          if (this.actual < this.totalPages()) {
              this.actual++;
          }
          this.getDataPages(this.actual);
      },
      isActive(numberPage) {
          return numberPage === this.actual ? "active" : "";
      },
      //TODO: Calcular Edad De Postulantes
      refreshList() {
          this.students();
      },
      filters() {
          let opciones = document.querySelectorAll('input[type="radio"]');
          document.querySelector('#btnradio').addEventListener('change', () => {
              opciones.forEach(opcion => {
                  if (opcion.checked) {
                      if (opcion.value === "Pendiente") {
                          let estudiantesfilter = this.listStudentsTemp.filter(estudiante => {
                              return estudiante.informationStudent.status === false;
                          });
                          this.listStudents = estudiantesfilter;
                          let start = this.actual * this.elementPage - this.elementPage;
                          let end = this.actual * this.elementPage;
                          this.dataPage = this.listStudents.slice(start, end);
                      } else if (opcion.value === "Aceptado") {
                          let estudiantesfilter = this.listStudentsTemp.filter(estudiante => {
                              return estudiante.informationStudent.status === true;
                          });
                          this.listStudents = estudiantesfilter;
                          let start = this.actual * this.elementPage - this.elementPage;
                          let end = this.actual * this.elementPage;
                          this.dataPage = this.listStudents.slice(start, end);
                      } else {
                          this.listStudents = this.listStudentsTemp;
                          let start = this.actual * this.elementPage - this.elementPage;
                          let end = this.actual * this.elementPage;
                          this.dataPage = this.listStudents.slice(start, end);
                      }
                  }
              })
          })
      },
      async addPromo(type) {
        this.newPromo.type = type; // Establecer el tipo según el modal
        const result = await this.$swal({
          title: "¿Está seguro de que desea enviar esta promoción?",
          text: "Una vez enviada, no podrá modificarla y tendrá que crear una nueva promoción.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, enviar",
          cancelButtonText: "Cancelar",
        });
        if (result.isConfirmed) {
          try {
            const response = await AdminService.addPromo(this.newPromo);
            this.$swal({
              position: "top-center",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 4000,
            });
            this.newPromo = { code: '', description: '', expirationDate: '' }; // Resetear el formulario
          } catch (error) {
            this.$swal({
              position: "top-end",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 2500,
            });
          }
        }
      },
      confirmDelete() {
        if (!this.deleteMessage) {
            this.$swal({
            position: "top-end",
            icon: "error",
            title: "Debes escribir un mensaje antes de confirmar",
            showConfirmButton: false,
            timer: 2500,
            });
            return;
        }
        AdminService.deleteUserVerification({
            id: this.id,
            message: this.deleteMessage, 
        })
        .then((res) => {
            this.$swal({
            position: "center",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2500,
            });
            this.students(); 
            this.deleteMessage = '';
            let deleteModal = new window.bootstrap.Modal('#modalDeleteConfirmation');
            deleteModal.hide();
            let userModal = new window.bootstrap.Modal('#modalUser');
            userModal.hide();
        })
        .catch((e) => {
            this.$swal({
            position: "center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
            });
        });
      },
  },
};
</script>
